import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    // redirect:{name:"表單項目"} ,
    
    beforeEnter() {location.href = 'http://uof-test.foodsmart.com.tw/UOFTEST/'}

    //  客戶正式區
    // beforeEnter() {location.href = 'https://uof.foodsmart.com.tw/'}
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: "/manager",
		component: () => import("@/views/Layout/Layout"),
		// meta: { managerAuth: true },
		children: [
      {
        path: 'list',
        name: '表單項目',
        component: () => import('@/views/List/List.vue'),
        meta: { managerAuth: true },
      },
      {
        path: 'index',
        name: 'Index',
        component: () => import('@/views/Mileage/Index.vue'),
        meta: { managerAuth: true },
      },
      {
        path: 'daily_inspection',
        name: '每日檢察項目表單',
        component: () => import('@/views/DailyInspectionItems/Form.vue'),
        meta: { managerAuth: true },
      },
      {
        path: 'weekly_inspection',
        name: '每週檢察項目表單',
        component: () => import('@/views/WeeklyInspectionItems/Form.vue'),
        meta: { managerAuth: true },
      },     
      {
        path: 'daily_maintenance',
        name: '每日里程維護',
        component: () => import('@/views/Mileage/Form.vue'),
        meta: { managerAuth: true },
      },
      {
        path: 'weekly_inspection_complete',
        name: '每日檢察項目表單完成',
        component: () => import('@/views/WeeklyInspectionItems/Complete.vue'),
        meta: { managerAuth: true },
      },
    ],
  },
]

const router = new VueRouter({
  // mode: 'history',
  // base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const isLogin = store.getters["isLogin"];
  // 如果未登入
  if (!isLogin) {
    // r 為帳號、p 為時間
    let qryParams = {};
    // 後端說不需 decode，但 query 好像自動幫我 decode 了...
    if (to.query.r && to.query.p) {
      qryParams = {
        UofAccount: encodeURIComponent(to.query.r),
        LogInTime: encodeURIComponent(to.query.p),
      };
    } else if (from.query.r && to.query.p) {
      qryParams = {
        UofAccount: encodeURIComponent(from.query.r),
        LogInTime: encodeURIComponent(from.query.p),
      };
    }
    if (qryParams.UofAccount && qryParams.LogInTime) {
      store.dispatch("login", qryParams).then(() => {
        let userInfo = store.getters["userInfo"];
        let loginMessage = store.getters["loginMessage"];
        if (userInfo && !loginMessage) {
          next();
        }
        // 逾時登入且不是 401 時
        if (loginMessage === "登入驗證已過期，請重新透過UOF登入") {
          alert("登入驗證已過期，請重新透過UOF登入<router>");
          window.location.href = "http://uof-test.foodsmart.com.tw/UOFTEST/";

          //  客戶正式區
          // window.location.href = "https://uof.foodsmart.com.tw/";
        }
      });
    } else if (localStorage.getItem("token")) {
      next();
    } else {
      // 直接輸入網址過來
      alert("請先透過UOF登入");
      window.location.href = "http://uof-test.foodsmart.com.tw/UOFTEST/";

      //  客戶正式區
      // window.location.href = "https://uof.foodsmart.com.tw/";
    }
  } else {
    next();
  }
});

export default router

