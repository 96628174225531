import Vue from 'vue';
import App from './App.vue';
import api from "./interceptors/axiosConfig--Notification.js";
import router from './router';
//import axios from 'axios'
import VueAxios from 'vue-axios'
import store from './store';
import EvaIcons from 'vue-eva-icons';
import ElementUI from 'element-ui';
import jquery from "jquery";
import 'element-ui/lib/theme-chalk/index.css';

import { ValidationProvider,ValidationObserver, extend } from 'vee-validate';
import Multiselect from 'vue-multiselect'
Vue.component('multiselect', Multiselect)
import 'vue-multiselect/dist/vue-multiselect.min.css';

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

extend('required', {
  ...required,
  message: '必填'
});

extend('min_value', {
  ...min_value,
  message: '需大於起里程'
});


extend('requiredOne', {
  ...required,
  message: '至少需填一項'
});




window.$ = jquery;

import { required,min_value } from 'vee-validate/dist/rules';

import dayjs from 'dayjs'
Vue.prototype.dayjs=dayjs;

Vue.use(EvaIcons);
Vue.use(ElementUI);
Vue.use(VueAxios, api);



import 'bootstrap';

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


// const api = axios;

// // 攔截器---------------------------------------------------------------------------
// api.defaults.timeout = 20000;
// api.interceptors.request.use(
//   (config) => {
//     if (localStorage.getItem("token")) {
//       config.headers.Authorization = "Bearer " + localStorage.getItem("token");
//     }
//     config.headers["Content-Type"] = "application/json; charset=utf-8";
//     config.withCredentials = true;
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );