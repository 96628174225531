import Vue from 'vue'
import axios from "axios";
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
      userInfo:null,
      loginMessage:""
  },
  mutations: {  //改state的值 step1    //other page commit呼叫這邊
    USERINFO(state, payload) {
      state.userInfo = payload;
    },
    LOGINMESSAGE(state, payload) {
      state.loginMessage = payload;
    },
  },
  actions: {    //改state的值 step2     //other page dispatch呼叫這邊
    login({ commit }, payload) {
      const url = `${process.env.VUE_APP_API}/Common/Auth/UOFRedirectLogIn`;
      return axios.post(url, payload).then((res) => {
        if (res.data.Data) {
          commit("USERINFO", res.data.Data);
          localStorage.setItem("token", res.data.Data.Token);
          localStorage.setItem("user", JSON.stringify(res.data.Data));
          
        }
        commit("LOGINMESSAGE", res.data.ErrorMessage);  
        
      });
    },
  },
  modules: {
  }
  ,getters:{  //for other page
    isLogin: (state) => {
      if (state.userInfo) {
        return true;
      }
      return false;
    },
    loginMessage: (state) => state.loginMessage,
    userInfo: (state) => state.userInfo,
  }
})
